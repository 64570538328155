


















































































































































































































































































import mixins from "vue-typed-mixins";
import MyMixin from "@/plugins/myMixin";
import { metadata } from "@/plugins/metadata";
import { types } from "@/services/westore/enums";

export default mixins(MyMixin).extend({
  name: "Warehouse",
  mounted() {
    this.trackMyPageView();
  },
  metaInfo: {
    title: metadata.Warehousing.title,
    htmlAttrs: {
      lang: "en-US",
    },
    meta: [
      { charset: "utf-8" },
      { name: "robots", content: "follow, index" },
      {
        name: "description",
        content: metadata.Warehousing.description,
      },
      {
        name: "viewport",
        content: "width=device-width, initial-scale=1, user-scalable=no",
      },
    ],
  },
  methods: {
    showHideContactsCustom() {
      if (this.globals.whatsappPopupVisible) {
        this.logGAEvent("Let's Chat", "Whatsapp open", `Warehouse Page`);
      }
      this.globals.whatsappPopupVisible = !this.globals.whatsappPopupVisible;
    },
  },
  data: () => ({
    warehousingItems: [
      {
        description:
          "Be flexible without the <br/> commitment of long-term <br/>contracts.",
        imageUrl: require("../../assets/new-site/warehouses/ben-01.jpg"),
      },
      {
        description: "Pay for the space you use,<br/> when you use it.",
        imageUrl: require("../../assets/new-site/warehouses/ben-02.jpg"),
      },
      {
        description:
          "Request new storage space across<br/> the UAE, extend its duration, or <br/>cancel at any point.",
        imageUrl: require("../../assets/new-site/warehouses/ben-003.jpg"),
      },
      {
        description:
          "Benefit from add-on services<br/> like transportation, same day<br/> delivery, fulfillment, and <br/>integration services.",
        imageUrl: require("../../assets/new-site/warehouses/ben-04.jpg"),
      },
      {
        description: "Manage your inventory in<br/> real time.",
        imageUrl: require("../../assets/new-site/warehouses/ben-05.jpg"),
      },
    ],

    warehousingItemsKW: [
      {
        title: "2 PL Service",
        description:
          " Opt for this straightforward solution if you just need us to store your inventory in a secure location.",
        imageUrl: require("../../assets/new-site/warehouses/ben-kw-01.jpg"),
      },
      {
        title: "3 PL Service",
        description:
          "Pick this if you want us to take care of the end-to-end logistics, including inventory management, packing, delivery, and more",
        imageUrl: require("../../assets/new-site/warehouses/ben-kw-02.jpg"),
      },
    ],
  }),
  computed: {
    types() {
      return types;
    },
  },
});
