import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"Fulfillment"}},[_c('ws-app-bar',{attrs:{"dark":""}}),_c(VSheet,{staticClass:"d-flex flex-column justify-space-between",attrs:{"color":"ws-black","dark":"","elevation":"0"}},[_c(VImg,{staticClass:"flex-grow section-lg hero-warehousing",attrs:{"eager":"","aspect-ratio":"4","position":_vm.$vuetify.breakpoint.mdAndUp ? '100% 30%' : '70%',"src":require('../../assets/new-site/services/fullfillment-hero.png'),"xgradient":"to right, rgba(12 ,25 ,34, .99),  rgba(12 ,25 ,34, .0)","gradient":_vm.$vuetify.breakpoint.mdAndUp
          ? 'to right, rgba(12 ,25 ,34, .99),  rgba(12 ,25 ,34, .0)'
          : 'to right, rgba(12 ,25 ,34, .7),  rgba(12 ,25 ,34, .7)',"min-height":_vm.$vuetify.breakpoint.mdAndDown
          ? 'Calc(60vh - 56px)'
          : 'Calc(80vh - 50px)'}},[_c(VContainer,{staticClass:"fill-height"},[_c(VRow,{attrs:{"wrap":""}},[_c(VCol,{staticClass:"px-5 px-lg-0",attrs:{"cols":"12","md":"8","lg":"6"}},[_c('h1',{staticClass:"nerrow-lines hidden-md-and-down"},[_c('ws-gradient-text',{staticClass:"text-uppercase"},[_vm._v(" Fulfilling your"),_c('br'),_vm._v(" business potential. ")])],1),_c('h1',{staticClass:"nerrow-lines hidden-lg-only"},[_vm._v(" Fulfilling your business potential. ")]),_c('p',{staticClass:"body-1 font-weight-bold my-7 hidden-md-and-down"},[_vm._v(" Let our highly capable team handle all your fulfillment needs, while you shift your attention to where your business needs you. They will pick, pack, label, and prepare your goods for transportation in a fast and standardized manner. They are also able to manage, process, and quality check all returned items. Sit back, track, and relax while we do the rest. ")]),_c('div',[_c(VBtn,{staticClass:"mr-3",attrs:{"light":"","color":"white"},on:{"click":function($event){_vm.logGAEvent(
                    'Fullfillment',
                    'Request a quote',
                    "Hero Banner"
                  );
                  _vm.$vuetify.goTo('#interest');}}},[_vm._v(" Request a quote ")])],1)])],1)],1)],1),_c('section',{staticClass:"benefits white section-lg py-5 py-md-10",attrs:{"id":"benefits"}},[_c(VContainer,[_c(VRow,{staticClass:"align-center px-3 px-lg-0 flex-column-reverse flex-lg-row"},[_c(VCol,{attrs:{"cols":"12","lg":"6"}},[_c('h3',[_c('ws-gradient-text',[_vm._v(" Benefits Of Our Fulfillment Services ")])],1),_c(VList,{staticClass:"transparent",attrs:{"dense":""}},_vm._l(([
                  'Reduced Costs',
                  'Increased Customer Satisfaction',
                  'Faster Delivery',
                  'Increased Efficiency',
                  'Increased Revenue and Growth' ]),function(p){return _c(VListItem,{key:p,staticClass:"pl-0"},[_c(VListItemIcon,[_c(VIcon,{attrs:{"size":"30"}},[_vm._v("$vuetify.icons.gradientCheck")])],1),_c(VListItemContent,[_c('span',{staticClass:"desc-1 ws-black--text",domProps:{"innerHTML":_vm._s(p)}})])],1)}),1)],1),_c(VCol,{attrs:{"cols":"12","lg":"6"}},[_c(VImg,{attrs:{"eager":"","src":require("../../assets/new-site/services/fullfillment-benefits.png")}})],1)],1)],1)],1)],1),_c('section',{staticClass:"section-lg"},[_c(VContainer,{staticClass:"pa-0"},_vm._l((_vm.fulfillment),function(item,i){return _c(VRow,{key:i,staticClass:"customs-clearance-row-wrapper d-flex align-items-center mb-5",class:{ 'ws-odd-row': i % 2 }},[_c(VCol,{staticClass:"p-0",attrs:{"cols":"12","lg":"6"}},[_c(VImg,{attrs:{"cover":"","eager":"","src":item.imageUrl}})],1),_c(VCol,{staticClass:"px-8 px-md-5 px-lg-16",attrs:{"cols":"12","lg":"6"}},[_c('h3',[_c('ws-gradient-text',{domProps:{"textContent":_vm._s(item.title)}})],1),_c('p',{staticClass:"body-1 font-weight-bold mt-5",domProps:{"textContent":_vm._s(item.description)}}),_c('div',[_c(VBtn,{staticClass:"mr-3 pl-0 text-decoration-underline",attrs:{"text":"","color":"accent"},on:{"click":function($event){_vm.logGAEvent('CustomClearance', 'Get a Quote', "Hero Banner");
                _vm.$vuetify.goTo('#interest');}}},[_vm._v(" Request a free demo ")])],1)])],1)}),1)],1),_c('section',{staticClass:"ws-black white--text section-md"},[_c(VContainer,[_c('h2',{staticClass:"text-center text-uppercase nerrow-lines"},[_c('ws-gradient-text',{staticClass:"text-uppercase"},[_vm._v(" Our Track Record ")])],1),_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c(VContainer,[(_vm.$route.params.country == 'ae')?_c(VRow,_vm._l((_vm.westoreLogistics),function(item,w){return _c(VCol,{key:w,attrs:{"cols":"12","md":"6"}},[_c(VCard,{staticClass:"Logistics-card",attrs:{"outlined":"","color":"transparent"}},[_c('div',{staticClass:"wrapper"},[_c('div',{staticClass:"right"},[_c('img',{attrs:{"height":_vm.$vuetify.breakpoint.mdAndDown
                            ? item.mobileHeight
                            : item.height,"src":item.image}}),_c('span',[_vm._v(_vm._s(item.number))])]),_c('div',{staticClass:"left"},[_c('h4',[_vm._v(_vm._s(item.title))]),_c('p',[_vm._v(_vm._s(item.desc))])])])])],1)}),1):(_vm.$route.params.country == 'kw')?_c(VRow,_vm._l((_vm.westoreLogisticsKw),function(item,w){return _c(VCol,{key:w,attrs:{"cols":"12","md":"6"}},[_c(VCard,{staticClass:"Logistics-card",attrs:{"outlined":"","color":"transparent"}},[_c('div',{staticClass:"wrapper"},[_c('div',{staticClass:"right"},[_c('img',{attrs:{"height":_vm.$vuetify.breakpoint.mdAndDown
                            ? item.mobileHeight
                            : item.height,"src":item.image}}),_c('span',[_vm._v(_vm._s(item.number))])]),_c('div',{staticClass:"left"},[_c('h4',[_vm._v(_vm._s(item.title))]),_c('p',[_vm._v(_vm._s(item.desc))])])])])],1)}),1):_c(VRow,_vm._l((_vm.westoreLogisticsQa),function(item,w){return _c(VCol,{key:w,attrs:{"cols":"12","md":"6"}},[_c(VCard,{staticClass:"Logistics-card",attrs:{"outlined":"","color":"transparent"}},[_c('div',{staticClass:"wrapper"},[_c('div',{staticClass:"right"},[_c('img',{attrs:{"height":_vm.$vuetify.breakpoint.mdAndDown
                            ? item.mobileHeight
                            : item.height,"src":item.image}}),_c('span',[_vm._v(_vm._s(item.number))])]),_c('div',{staticClass:"left"},[_c('h4',[_vm._v(_vm._s(item.title))]),_c('p',[_vm._v(_vm._s(item.desc))])])])])],1)}),1)],1)],1)],1),_c('div',{staticClass:"text-center my-5 hidden-sm-and-down"},[_c(VBtn,{staticClass:"text-decoration-underline",attrs:{"text":"","color":"accent"},on:{"click":function($event){_vm.logGAEvent('Fullfillment', 'Request a quote', "Register Now");
            _vm.$vuetify.goTo('#interest');}}},[_vm._v(" Register Now To Get That Competitive Edge ")])],1)],1)],1),_c('section',{staticClass:"ws-banner ws-banner-pink section-lg white--text",attrs:{"id":"interest"}},[_c(VContainer,{attrs:{"dark":""}},[_c(VRow,[_c(VCol,{attrs:{"cols":"12","md":"6"}},[_c('h2',{staticClass:"mb-6"},[_vm._v(" We Store."),_c('br'),_vm._v(" You Grow. ")]),_c('h3',{staticClass:"mb-5"},[_vm._v(" Solving the hassles of your storage and delivery. ")]),_c('h4',[_vm._v("We will do the heavy lifting for you!")])]),_c(VCol,{attrs:{"cols":"12","md":"6","lg":"4","offset-lg":"1"}},[_c('ws-common-form',{attrs:{"title":"Get a Call","title-color":"primary","color":"white","light":"","comments-label":"Comments / Questions","submit-color":"ws-green","form-type":_vm.types.CustomerInterest}})],1)],1)],1)],1),_c('ws-footer')],1)}
var staticRenderFns = []

export { render, staticRenderFns }