



















































































































































import mixins from "vue-typed-mixins";
import MyMixin from "@/plugins/myMixin";
import { metadata } from "@/plugins/metadata";
import { types } from "@/services/westore/enums";

export default mixins(MyMixin).extend({
  name: "ProjectManagement",
  mounted() {
    this.trackMyPageView();
  },
  metaInfo: {
    title: metadata.ProjectManagement.title,
    htmlAttrs: {
      lang: "en-US",
    },
    meta: [
      { charset: "utf-8" },
      { name: "robots", content: "follow, index" },
      {
        name: "description",
        content: metadata.ProjectManagement.description,
      },
      {
        name: "viewport",
        content: "width=device-width, initial-scale=1, user-scalable=no",
      },
    ],
  },
  methods: {
    showHideContactsCustom() {
      if (this.globals.whatsappPopupVisible) {
        this.logGAEvent("Let's Chat", "Whatsapp open", `Warehouse Page`);
      }
      this.globals.whatsappPopupVisible = !this.globals.whatsappPopupVisible;
    },
  },
  data: () => ({
    projectmanagementItems: [
      {
        title: "Simplifying complex cases",
        description:
          "We understand that every shipment case is different, and some require a tailored approach due to their size, weight, urgency, value, or fragility. With the support of our multi-channel software, our experienced team is well-equipped to handle projects with unusual challenges or specifications. We will customize the entire service and experience based on your cargo needs, no matter how complex the case is. Rest assured, your cargo will arrive at its destination securely, in excellent condition, and on time.",
        imageUrl: require("../../assets/new-site/services/project-management1.jpg"),
      },
    ],
  }),
  computed: {
    types() {
      return types;
    },
  },
});
