import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"lastmile"}},[_c('ws-app-bar',{attrs:{"color":_vm.$vuetify.breakpoint.smAndDown ? 'white' : 'ws-black'}}),_c(VSheet,{staticClass:"d-flex flex-column justify-space-between",attrs:{"color":_vm.$vuetify.breakpoint.mdAndDown ? 'white' : 'ws-black',"dark":"","elevation":"0"}},[_c(VImg,{staticClass:"flex-grow section-md hidden-sm-and-down hero-warehousing",attrs:{"eager":"","aspect-ratio":"4","position":_vm.$vuetify.breakpoint.mdAndUp ? '100% 55%' : '20%',"src":require('../../assets/new-site/services/lastmile-hero.jpg'),"xgradient":"to right, rgba(12 ,25 ,34, .99),  rgba(12 ,25 ,34, .0)","gradient":_vm.$vuetify.breakpoint.mdAndUp
          ? 'to right, rgba(12 ,25 ,34, .99),  rgba(12 ,25 ,34, .0)'
          : 'to right, rgba(12 ,25 ,34, .7),  rgba(12 ,25 ,34, .7)'}},[_c(VContainer,{staticClass:"py-14"},[_c(VRow,{attrs:{"wrap":""}},[_c(VCol,{attrs:{"cols":"12","md":"6","xlg":"5"}},[_c('h1',{staticClass:"mb-4"},[_c('ws-gradient-text',{staticClass:"text-uppercase"},[_vm._v(" Delivering excellent service "),_c('br'),_vm._v("from door to door ")])],1),_c('p',{staticClass:"body-1 font-weight-bold my-6"},[_vm._v(" Offering a prompt and qualitative delivery service is crucial to the overall customer experience and efficiency of a business. We recognize the importance of starting and ending on a strong note when it comes to logistics. This is why we offer you any type of delivery service you may require: from last-mile, same-day, and next-day deliveries within the GCC to international package deliveries. In addition, our team is trained to offer specialty delivery services for food, fragile, or unique items. ")]),_c('div',[_c(VBtn,{staticClass:"mr-3",attrs:{"color":"white","light":""},on:{"click":function($event){_vm.logGAEvent('LastMile', 'Get a quote', "Hero Banner");
                  _vm.$vuetify.goTo('#interest');}}},[_vm._v(" Get a Quote ")])],1)])],1)],1)],1),_c(VContainer,{staticClass:"hidden-md-and-up"},[_c(VRow,{staticClass:"d-flex justify-content-center",attrs:{"wrap":""}},[_c(VCol,{staticClass:"text-center pt-5 pb-10",attrs:{"cols":"12","md":"8"}},[_c('h1',{staticClass:"mb-8"},[_c('ws-gradient-text',{staticClass:"text-uppercase"},[_vm._v(" Delivering excellent service from door to door ")])],1),_c(VBtn,{attrs:{"color":"ws-black","dark":""},on:{"click":function($event){_vm.logGAEvent('LastMile', 'Get a quote', "Hero Banner");
              _vm.$vuetify.goTo('#interest');}}},[_vm._v(" Get A Quote ")])],1)],1)],1)],1),_c('section',{staticClass:"section-lg py-0 py-md-5"},[_c(VContainer,{staticClass:"pa-0"},_vm._l((_vm.lastmileItems),function(item,i){return _c(VRow,{key:i,staticClass:"my-lg-5"},[_c('div',{staticClass:"d-flex align-items-center flex-column flex-lg-row row-wrapper"},[_c(VCol,{staticClass:"p-0",attrs:{"cols":"12","lg":"6"}},[_c(VImg,{attrs:{"eager":"","src":item.imageUrl}})],1),_c(VCol,{staticClass:"py-8",attrs:{"cols":"12","lg":"5","offset-lg":"1"}},[_c('h3',[_c('ws-gradient-text',{domProps:{"textContent":_vm._s(item.title)}})],1),_c('ul',_vm._l(([
                  '4-hour delivery, same-day delivery and next-day delivery across the GCC',
                  'International, cross-border delivery.',
                  'COD (cash on delivery) & remit to the merchant.',
                  'White glove delivery service.',
                  'Specialty delivery for food & temperature- controlled delivery.',
                  'Amazon (prime) fulfillment support.',
                  'Noon fulfillment support.' ]),function(p){return _c('li',{key:p},[_c('span',{staticClass:"desc-1"},[_vm._v(_vm._s(p))])])}),0)])],1)])}),1)],1),_c('section',{staticClass:"ws-banner ws-banner-pink section-lg white--text",attrs:{"id":"interest"}},[_c(VContainer,{attrs:{"dark":""}},[_c(VRow,[_c(VCol,{attrs:{"cols":"12","md":"6"}},[_c('h2',{staticClass:"mb-6"},[_vm._v(" We Store."),_c('br'),_vm._v(" You Grow. ")]),_c('h3',{staticClass:"mb-5"},[_vm._v(" Solving the hassles of your storage and delivery. ")]),_c('h4',[_vm._v("We will do the heavy lifting for you!")])]),_c(VCol,{attrs:{"cols":"12","md":"6","lg":"4","offset-lg":"1"}},[_c('ws-common-form',{attrs:{"title":"Get a Call","title-color":"primary","color":"white","light":"","comments-label":"Comments / Questions","submit-color":"ws-green","form-type":_vm.types.CustomerInterest}})],1)],1)],1)],1),_c('ws-footer')],1)}
var staticRenderFns = []

export { render, staticRenderFns }