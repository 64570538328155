
























































































































































































































































































































import mixins from "vue-typed-mixins";
import MyMixin from "@/plugins/myMixin";
import { metadata } from "@/plugins/metadata";
import { types } from "@/services/westore/enums";

export default mixins(MyMixin).extend({
  name: "Fulfillment",
  mounted() {
    this.trackMyPageView();
    this.$nextTick(() => {
      // this.currentCapability = this.warehouseFeatures[0];
    });
  },
  metaInfo: {
    title: metadata.Fulfillment.title,
    htmlAttrs: {
      lang: "en-US",
    },
    meta: [
      { charset: "utf-8" },
      { name: "robots", content: "follow, index" },
      {
        name: "description",
        content: metadata.Fulfillment.description,
      },
      {
        name: "viewport",
        content: "width=device-width, initial-scale=1, user-scalable=no",
      },
    ],
  },
  data: () => ({
    westoreLogistics: [
      {
        title: "On Time Shipping",
        desc: "When using Westore delivery services",
        image: require("@/assets/new-site/home/logistics1.png"),
        number: "99%",
        height: "55",
        mobileHeight: "55",
      },
      {
        title: "Order Accuracy",
        desc: "Improving customer satisfaction",
        image: require("@/assets/new-site/home/logistics2.png"),
        number: "99%",
        height: "80",
        mobileHeight: "55",
      },
      {
        title: "Picking Accuracy",
        desc: "Resulting in improved user experience",
        image: require("@/assets/new-site/home/logistics3.png"),
        number: "98%+",
        height: "70",
        mobileHeight: "55",
      },
      {
        title: "Platform Integration",
        desc: "In less than 1 minute",
        image: require("@/assets/new-site/home/logistics4.png"),
        number: "3-Click",
        height: "70",
        mobileHeight: "55",
      },
      {
        title: "Storage Spaces",
        desc: "Mainland and freeze ones",
        image: require("@/assets/new-site/home/logistics5.png"),
        number: "3 Million sq. ft",
        height: "55",
        mobileHeight: "55",
      },
      {
        title: "Warehouse Locations",
        desc: "Across the GCC",
        image: require("@/assets/new-site/home/logistics6.png"),
        number: "160 +",
        height: "55",
        mobileHeight: "55",
      },
      {
        title: "Cost Reduction",
        desc: "Compared to traditional warehouses",
        image: require("@/assets/new-site/home/logistics7.png"),
        number: "15% +",
        height: "60",
        mobileHeight: "55",
      },
      {
        title: "Increase in E-commerce",
        desc: "Conversion rate with our next day delivery service",
        image: require("@/assets/new-site/home/logistics8.png"),
        number: "25%",
        height: "60",
        mobileHeight: "55",
      },
    ],
    westoreLogisticsKw: [
      {
        title: "On Time Shipping",
        desc: "When using Westore delivery services",
        image: require("@/assets/new-site/home/logistics1.png"),
        number: "99%",
        height: "55",
        mobileHeight: "55",
      },
      {
        title: "Order Accuracy",
        desc: "Improving customer satisfaction",
        image: require("@/assets/new-site/home/logistics2.png"),
        number: "99%",
        height: "80",
        mobileHeight: "55",
      },
      {
        title: "Picking Accuracy",
        desc: "Resulting in improved user experience",
        image: require("@/assets/new-site/home/logistics3.png"),
        number: "98%+",
        height: "70",
        mobileHeight: "55",
      },
      {
        title: "Platform Integration",
        desc: "In less than 1 minute",
        image: require("@/assets/new-site/home/logistics4.png"),
        number: "3-Click",
        height: "70",
        mobileHeight: "55",
      },
      {
        title: "Storage Spaces",
        desc: "Temperature controlled and Ambient",
        image: require("@/assets/new-site/home/logistics5.png"),
        number: "28,000  SQM",
        height: "55",
        mobileHeight: "55",
      },
      {
        title: "Warehouse Locations",
        desc: "Across Kuwait",
        image: require("@/assets/new-site/home/logistics6.png"),
        number: "4",
        height: "55",
        mobileHeight: "55",
      },
      {
        title: "Cost Reduction",
        desc: "Compared to traditional warehouses",
        image: require("@/assets/new-site/home/logistics7.png"),
        number: "15% +",
        height: "60",
        mobileHeight: "55",
      },
      {
        title: "Increase in E-commerce",
        desc: "Conversion rate with our next day delivery service",
        image: require("@/assets/new-site/home/logistics8.png"),
        number: "25%",
        height: "60",
        mobileHeight: "55",
      },
    ],
    westoreLogisticsQa: [
      {
        title: "On Time Shipping",
        desc: "When using Westore delivery services",
        image: require("@/assets/new-site/home/logistics1.png"),
        number: "99%",
        height: "55",
        mobileHeight: "55",
      },
      {
        title: "Order Accuracy",
        desc: "Improving customer satisfaction",
        image: require("@/assets/new-site/home/logistics2.png"),
        number: "99%",
        height: "80",
        mobileHeight: "55",
      },
      {
        title: "Picking Accuracy",
        desc: "Resulting in improved user experience",
        image: require("@/assets/new-site/home/logistics3.png"),
        number: "98%+",
        height: "70",
        mobileHeight: "55",
      },
      {
        title: "Platform Integration",
        desc: "In less than 1 minute",
        image: require("@/assets/new-site/home/logistics4.png"),
        number: "3-Click",
        height: "70",
        mobileHeight: "55",
      },
      {
        title: "Storage Spaces",
        desc: "Temperature controlled and Ambient",
        image: require("@/assets/new-site/home/logistics5.png"),
        number: "28,000  SQM",
        height: "55",
        mobileHeight: "55",
      },
      {
        title: "Warehouse Locations",
        desc: "Across Qatar",
        image: require("@/assets/new-site/home/logistics6.png"),
        number: "4",
        height: "55",
        mobileHeight: "55",
      },
      {
        title: "Cost Reduction",
        desc: "Compared to traditional warehouses",
        image: require("@/assets/new-site/home/logistics7.png"),
        number: "15% +",
        height: "60",
        mobileHeight: "55",
      },
      {
        title: "Increase in E-commerce",
        desc: "Conversion rate with our next day delivery service",
        image: require("@/assets/new-site/home/logistics8.png"),
        number: "25%",
        height: "60",
        mobileHeight: "55",
      },
    ],
    fulfillment: [
      {
        title: "Connecting you with multiple retailers in one go",
        description:
          "If you run an e-commerce business, this service was designed for you.Use our integrated platform to manage, process, and track all your online orders from various sales channels.",
        imageUrl: require("../../assets/new-site/services/Customs-clearance3.jpg"),
      },
      {
        title: "Fulfilling your business potential",
        description:
          "Let our highly capable team handle all your fulfillment needs, while you shift your attention to where your business needs you.They will pick, pack, label, and prepare your goods for transportation in a fast and standardized manner. They are also able to manage, process, and quality check all returned items.Sit back, track, and relax while we do the rest.",
        imageUrl: require("../../assets/new-site/services/Customs-clearance4.png"),
      },
      {
        title: "Providing personal service with a touch of technology",
        description:
          "While our cutting-edge technology does all the heavy lifting, our experienced team is always on standby to support you. If you have a special request, query, or problem, we are both available and qualified to assist you with all matters. Our main priority is giving you a smooth and satisfying experience by catering to your specific business needs.",
        imageUrl: require("../../assets/new-site/services/Customs-clearance5.png"),
      },
    ],
  }),

  methods: {
    showHideContactsCustom() {
      if (!this.globals.whatsappPopupVisible) {
        this.logGAEvent("Let's Chat", "Whatsapp open", `Home Page`);
      }
      this.globals.whatsappPopupVisible = !this.globals.whatsappPopupVisible;
    },
  },
  computed: {
    types() {
      return types;
    },
  },
});
