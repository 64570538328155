import { render, staticRenderFns } from "./ProjectManagement.vue?vue&type=template&id=0040fe8e&scoped=true&"
import script from "./ProjectManagement.vue?vue&type=script&lang=ts&"
export * from "./ProjectManagement.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0040fe8e",
  null
  
)

export default component.exports