import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"Warehousing"}},[_c('ws-app-bar',{attrs:{"dark":""}}),_c(VSheet,{staticClass:"d-flex flex-column justify-space-between",attrs:{"color":"ws-black","dark":"","elevation":"0"}},[(_vm.$route.params.country == 'ae')?_c(VImg,{staticClass:"flex-grow section-md",attrs:{"eager":"","aspect-ratio":"4","position":_vm.$vuetify.breakpoint.mdAndUp ? '100% 55%' : 'center center',"src":require('../../assets/new-site/services/Warehousing-hero.jpg'),"xgradient":"to right, rgba(12 ,25 ,34, .99),  rgba(12 ,25 ,34, .0)","gradient":_vm.$vuetify.breakpoint.mdAndUp
          ? 'to right, rgba(12 ,25 ,34, .99),  rgba(12 ,25 ,34, .0)'
          : 'to right, rgba(12 ,25 ,34, .7),  rgba(12 ,25 ,34, .7)'}},[_c(VContainer,{staticClass:"py-14"},[_c(VRow,{attrs:{"wrap":""}},[_c(VCol,{staticClass:"px-5 px-lg-0",attrs:{"cols":"12","md":"5","xlg":"4"}},[_c('h1',{staticClass:"hidden-sm-and-down"},[_c('ws-gradient-text',{staticClass:"text-uppercase"},[_vm._v(" Giving you the space"),_c('br'),_vm._v("to grow ")])],1),_c('h1',{staticClass:"hidden-md-and-up"},[_vm._v("Giving you the space to grow")]),_c('p',{staticClass:"body-1 font-weight-bold my-6 hidden-sm-and-down"},[_vm._v(" Looking for warehousing solutions? "),_c('br'),_vm._v("Whether you are just starting out, in need of bulk storage, or expanding your e-commerce business, we offer a myriad of on-demand warehousing options across the UAE with the click of a button. Never allow a lack of storage space to become the bottleneck within your company ")]),_c('div',[_c(VBtn,{staticClass:"mr-3",attrs:{"light":"","color":"white"},on:{"click":function($event){_vm.logGAEvent('Warehousing', 'Get Free Demo', "Hero Banner");
                  _vm.$vuetify.goTo('#list-your-warehouse');}}},[_vm._v(" Get Free Demo ")])],1)])],1)],1)],1):_c(VImg,{staticClass:"flex-grow section-md hero-warehousing",attrs:{"eager":"","aspect-ratio":"4","position":_vm.$vuetify.breakpoint.mdAndUp ? '100% 55%' : '70%',"src":require('../../assets/new-site/warehouses/hero-kw.jpg'),"xgradient":"to right, rgba(12 ,25 ,34, .99),  rgba(12 ,25 ,34, .0)","gradient":_vm.$vuetify.breakpoint.mdAndUp
          ? 'to right, rgba(12 ,25 ,34, .99),  rgba(12 ,25 ,34, .0)'
          : 'to right, rgba(12 ,25 ,34, .7),  rgba(12 ,25 ,34, .7)'}},[_c(VContainer,{staticClass:"py-14"},[_c(VRow,{attrs:{"wrap":""}},[_c(VCol,{staticClass:"px-5 px-lg-0",attrs:{"cols":"12","md":"8","lg":"5"}},[_c('h1',{staticClass:"hidden-sm-and-down"},[_c('ws-gradient-text',{staticClass:"text-uppercase"},[_vm._v(" Track your warehouse inventory — anytime,"),_c('br'),_vm._v(" anywhere ")])],1),_c('h1',{staticClass:"hidden-md-and-up"},[_vm._v(" Track your warehouse inventory — anytime, anywhere ")]),_c('p',{staticClass:"body-1 font-weight-bold my-6 hidden-sm-and-down"},[_vm._v(" Whether you are just starting out, in need of bulk storage, or expanding your e-commerce business, we can offer you a myriad of storage options suited for you. ")]),_c('div',[_c(VBtn,{staticClass:"mr-3",attrs:{"light":"","color":"white"},on:{"click":function($event){_vm.logGAEvent('Warehousing', 'Get Free Demo', "Hero Banner");
                  _vm.$vuetify.goTo('#list-your-warehouse');}}},[_vm._v(" Get Free Demo ")])],1)])],1)],1)],1)],1),(_vm.$route.params.country == 'ae')?_c('section',{staticClass:"section-lg"},[_c(VContainer,[_c('h2',{staticClass:"text-center nerrow-lines mb-12"},[_vm._v(" This new concept allows you to ")]),_c(VRow,{staticClass:"justify-content-center"},[_vm._l((_vm.warehousingItems),function(item,i){return _c(VCol,{key:i,staticClass:"pa-0 px-5 mb-10",attrs:{"cols":"12","md":"6","lg":"4"}},[_c('div',{staticClass:"benfits-card"},[_c('div',{staticClass:"benfits-img"},[_c('img',{attrs:{"src":item.imageUrl}})]),_c('p',{staticClass:"body-1 font-weight-bold px-2 text-center mt-4",domProps:{"innerHTML":_vm._s(item.description)}})])])}),_c('div',{staticClass:"text-center"},[_c(VBtn,{staticClass:"mb-4",attrs:{"x-large":"","color":"ws-black","dark":""},on:{"click":function($event){_vm.logGAEvent('Warehousing', 'Get Free Demo', "Hero Banner");
              _vm.$vuetify.goTo('#list-your-warehouse');}}},[_vm._v(" Get Free Demo ")])],1)],2)],1)],1):_c('section',{staticClass:"section-lg"},[_c(VContainer,{staticClass:"pa-0"},[_c('h2',{staticClass:"text-center nerrow-lines mb-12"},[_vm._v("The Services")]),_vm._l((_vm.warehousingItemsKW),function(item,i){return _c(VRow,{key:i,staticClass:"row-wrapper d-flex align-items-center mx-5 mx-md-0",class:{ 'ws-odd-row': i % 2 }},[_c(VCol,{staticClass:"p-0",attrs:{"cols":"12","md":"6"}},[_c(VImg,{attrs:{"eager":"","src":item.imageUrl}})],1),_c(VCol,{staticClass:"px-5 px-lg-16",attrs:{"cols":"12","md":"6"}},[_c('h3',[_c('ws-gradient-text',{domProps:{"textContent":_vm._s(item.title)}})],1),_c('p',{staticClass:"body-1 font-weight-bold mt-3",domProps:{"textContent":_vm._s(item.description)}})])],1)})],2)],1),_c('section',{staticClass:"ws-primary"},[_c(VContainer,[_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[(_vm.$route.params.country == 'ae')?_c('div',{staticClass:"text-center"},[_c('p',{staticClass:"text-white mb-0"},[_vm._v(" Do you have some additional unutilized space? Become ")]),_c(VBtn,{staticClass:"text-decoration-underline",attrs:{"text":"","color":"accent"},on:{"click":function($event){return _vm.$router.push({
                  name: 'ServiceProvider',
                  params: { country: _vm.$route.params.country },
                })}}},[_vm._v(" a warehousing storage provider ")])],1):_c('div',{staticClass:"text-center"},[_c('p',{staticClass:"text-white mb-5 text-uppercase"},[_vm._v(" Whichever service you are leaning towards, we are"),_c('br'),_vm._v(" always able to further tailor it to your needs. In any "),_c('br'),_vm._v(" case, your inventory will always be securely stored. ")]),_c(VBtn,{attrs:{"light":"","color":"white"},on:{"click":function($event){_vm.logGAEvent('Warehousing', 'Get Free Demo', "Warehousing");
                _vm.$vuetify.goTo('#list-your-warehouse');}}},[_vm._v(" Get Free Demo ")])],1)])],1)],1)],1),_c('section',{staticClass:"white--text ws-banner ws-banner-pink section-lg",attrs:{"id":"list-your-warehouse"}},[_c(VContainer,[_c(VRow,[_c(VCol,{attrs:{"cols":"12","md":"6"}},[_c('h2',{staticClass:"mb-6"},[_vm._v("We Store. You Grow.")]),_c('h3',{staticClass:"mb-5"},[_vm._v(" Partner up with WeStore and tell us about your business. ")]),_c(VList,{staticClass:"transparent",attrs:{"dense":"","dark":""}},_vm._l(([
                'Optimise unused space',
                'Introduce new revenue streams',
                'No long-term commitments' ]),function(p){return _c(VListItem,{key:p},[_c(VListItemIcon,[_c(VIcon,{attrs:{"color":"white"}},[_vm._v("mdi-check-circle-outline")])],1),_c(VListItemContent,[_c('span',{staticClass:"body-1 font-weight-bold gray-text",domProps:{"innerHTML":_vm._s(p)}})])],1)}),1)],1),_c(VCol,{attrs:{"cols":"12","md":"6","lg":"4","offset-lg":"1"}},[_c('ws-common-form',{attrs:{"title":"Get a Call","title-color":"primary","color":"white","light":"","comments-label":"Comments / Questions","submit-color":"ws-green","form-type":_vm.types.VendorInterest}})],1)],1)],1)],1),_c('ws-footer')],1)}
var staticRenderFns = []

export { render, staticRenderFns }