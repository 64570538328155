import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"freight"}},[_c('ws-app-bar',{attrs:{"color":_vm.$vuetify.breakpoint.smAndDown ? 'white' : 'ws-black'}}),_c(VSheet,{staticClass:"d-flex flex-column justify-space-between",attrs:{"color":_vm.$vuetify.breakpoint.mdAndDown ? 'white' : 'ws-black',"dark":"","elevation":"0"}},[_c('div',{staticClass:"hero-wrapper hidden-sm-and-down"},[_c('video',{staticClass:"flex-grow section-md",attrs:{"src":require('../../assets/new-site/services/freight-hero.mp4'),"autoplay":"","muted":"","loop":"","playsinline":""},domProps:{"muted":true}}),_c(VContainer,{staticClass:"overlayText py-14"},[_c(VRow,{attrs:{"wrap":""}},[_c(VCol,{attrs:{"cols":"12","md":"5","xlg":"4"}},[_c('h1',[_c('ws-gradient-text',{staticClass:"text-uppercase"},[_vm._v(" Leaving your cargo in "),_c('br'),_vm._v(" capable, tech-savvy hands ")])],1),_c('p',{staticClass:"body-1 font-weight-bold my-6"},[_vm._v(" Whether you are shipping high-value goods or heavy machinery, we offer tailored solutions that best fit your budget, timeline, and cargo requirements. As a service provider, we will manage it all: the packing, loading, unloading, warehousing, and documentation of your shipment by land, air, or sea. With our comprehensive platform, you can monitor your shipment in real time, allowing you to stay updated, in control, and flexible. ")]),_c('div',[_c(VBtn,{staticClass:"mr-3",attrs:{"color":"white","light":""},nativeOn:{"click":function($event){_vm.logGAEvent('Freight', 'Get a Quote', "Hero Banner");
                  _vm.$vuetify.goTo('#interest');}}},[_vm._v(" Get a Quote ")])],1)])],1)],1)],1),_c(VContainer,{staticClass:"hidden-md-and-up"},[_c(VRow,{attrs:{"wrap":""}},[_c(VCol,{staticClass:"text-center px-5 px-md-0 pt-5 pb-10",attrs:{"cols":"12"}},[_c('h1',{staticClass:"mb-8"},[_c('ws-gradient-text',{staticClass:"text-uppercase"},[_vm._v(" Leaving your cargo in "),_c('br'),_vm._v(" capable, tech-savvy hands ")])],1),_c('p',{staticClass:"my-6 ws-black--text"},[_vm._v(" Whether you are shipping high-value goods or heavy machinery, we offer tailored solutions that best fit your budget, timeline, and cargo requirements. As a service provider, we will manage it all: the packing, loading, unloading, warehousing, and documentation of your shipment by land, air, or sea. With our comprehensive platform, you can monitor your shipment in real time, allowing you to stay updated, in control, and flexible. ")]),_c(VBtn,{attrs:{"color":"ws-black","dark":""},nativeOn:{"click":function($event){_vm.logGAEvent('Freight', 'Get a Quote', "Hero Banner");
              _vm.$vuetify.goTo('#interest');}}},[_vm._v(" Get A Quote ")])],1)],1)],1)],1),_c('section',{staticClass:"section-lg benefits"},[_c(VContainer,{staticClass:"pa-0"},[_c('h2',{staticClass:"text-center text-white nerrow-lines mb-4 mb-md-12"},[_vm._v(" Benefits of shipping with us ")]),_c(VRow,[_c(VCol,{staticClass:"d-none d-md-flex",attrs:{"cols":"12","md":"6"}},[_c(VImg,{attrs:{"eager":"","src":require("../../assets/new-site/services/freight4.jpg")}})],1),_c(VCol,{staticClass:"d-flex align-items-center",attrs:{"cols":"12","md":"6"}},[_c(VList,{staticClass:"transparent",attrs:{"dense":""}},[_vm._l(([
                'Take advantage of our global network of reliable and qualitative cargo partners.',
                'Benefit from a streamlined digital process.',
                'Receive tailored solutions fit for you freight needs.' ]),function(p){return _c(VListItem,{key:p},[_c(VListItemIcon,{staticClass:"mr-5"},[_c(VIcon,{attrs:{"size":"30"}},[_vm._v("$vuetify.icons.gradientCheck")])],1),_c(VListItemContent,[_c('span',{domProps:{"innerHTML":_vm._s(p)}})])],1)}),_c(VBtn,{staticClass:"ml-16 mt-5 text-decoration-underline",attrs:{"text":"","small":"","color":"accent"},nativeOn:{"click":function($event){_vm.logGAEvent('Freight', 'Get a Quote', "Hero Banner");
                _vm.$vuetify.goTo('#interest');}}},[_vm._v(" Get a Quote ")])],2)],1)],1)],1)],1),_c('section',{staticClass:"section-lg py-0 py-md-5"},[_c(VContainer,{staticClass:"pa-0"},_vm._l((_vm.freightItems),function(si,i){return _c(VRow,{key:i,staticClass:"freight-row-wrapper d-flex align-items-center mb-5",class:{ 'ws-odd-row': i % 2 }},[_c(VCol,{staticClass:"p-0",attrs:{"cols":"12","md":"6"}},[_c(VImg,{attrs:{"cover":"","eager":"","src":si.imageUrl}})],1),_c(VCol,{staticClass:"px-8 px-lg-16",attrs:{"cols":"12","md":"6"}},[_c('h3',{staticClass:"mt-3 mt-md-0"},[_c('ws-gradient-text',{domProps:{"textContent":_vm._s(si.title)}})],1),_c('p',{staticClass:"body-1 font-weight-bold mt-5",domProps:{"textContent":_vm._s(si.description)}}),_c('div',[_c(VBtn,{staticClass:"mr-3 mt-3 mt-md-0 pl-0 text-decoration-underline",attrs:{"text":"","color":"accent"},nativeOn:{"click":function($event){_vm.logGAEvent('Freight', 'Get a Quote', "Hero Banner");
                _vm.$vuetify.goTo('#interest');}}},[_vm._v(" Get a Quote ")])],1)])],1)}),1)],1),_c('section',{staticClass:"ws-banner ws-banner-pink section-lg white--text",attrs:{"id":"interest"}},[_c(VContainer,{attrs:{"dark":""}},[_c(VRow,[_c(VCol,{attrs:{"cols":"12","md":"6"}},[_c('h2',{staticClass:"mb-6"},[_vm._v(" We Store."),_c('br'),_vm._v(" You Grow. ")]),_c('h3',{staticClass:"mb-5"},[_vm._v(" Solving the hassles of your storage and delivery. ")]),_c('h4',[_vm._v("We will do the heavy lifting for you!")])]),_c(VCol,{attrs:{"cols":"12","md":"6","lg":"4","offset-lg":"1"}},[_c('ws-common-form',{attrs:{"title":"Get a Call","title-color":"primary","color":"white","light":"","comments-label":"Comments / Questions","submit-color":"ws-green","form-type":_vm.types.CustomerInterest}})],1)],1)],1)],1),_c('ws-footer')],1)}
var staticRenderFns = []

export { render, staticRenderFns }