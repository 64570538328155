





































































































































































































































































import mixins from "vue-typed-mixins";
import MyMixin from "@/plugins/myMixin";
import { metadata } from "@/plugins/metadata";
import { types } from "@/services/westore/enums";

export default mixins(MyMixin).extend({
  name: "Freight",
  mounted() {
    this.trackMyPageView();
  },
  metaInfo: {
    title: metadata.Freight.title,
    htmlAttrs: {
      lang: "en-US",
    },
    meta: [
      { charset: "utf-8" },
      { name: "robots", content: "follow, index" },
      {
        name: "description",
        content: metadata.Freight.description,
      },
      {
        name: "viewport",
        content: "width=device-width, initial-scale=1, user-scalable=no",
      },
    ],
  },
  methods: {
    showHideContactsCustom() {
      if (this.globals.whatsappPopupVisible) {
        this.logGAEvent("Let's Chat", "Whatsapp open", `Warehouse Page`);
      }
      this.globals.whatsappPopupVisible = !this.globals.whatsappPopupVisible;
    },
  },
  data: () => ({
    freightItems: [
      {
        title: "Air freight",
        description:
          "We provide operations and logistics for small, medium and ecommerce businesses. Store a little or a lot – and only pay for the space and time you need.",
        imageUrl: require("../../assets/new-site/services/freight1.jpg"),
      },
      {
        title: "Sea freight",
        description:
          "If you are shipping to a far-away destination or simply transporting a heavy load, sea freight. may be the most affordable way to go.We also offer consolidated shipping for your convenience.",
        imageUrl: require("../../assets/new-site/services/freight2.jpg"),
      },
      {
        title: "Land freight",
        description:
          "Whatever your cargo requirements may be, our team is well-equipped to manage your projects with ease—no matter the size, tight deadlines, budget, or complexity of the case.",
        imageUrl: require("../../assets/new-site/services/freight3.jpg"),
      },
    ],
  }),
  computed: {
    types() {
      return types;
    },
  },
});
