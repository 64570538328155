





















































































































































































import mixins from "vue-typed-mixins";
import MyMixin from "@/plugins/myMixin";
import { metadata } from "@/plugins/metadata";
import { types } from "@/services/westore/enums";

export default mixins(MyMixin).extend({
  name: "CustomsClearance",
  mounted() {
    this.trackMyPageView();
  },
  metaInfo: {
    title: metadata.CustomsClearance.title,
    htmlAttrs: {
      lang: "en-US",
    },
    meta: [
      { charset: "utf-8" },
      { name: "robots", content: "follow, index" },
      {
        name: "description",
        content: metadata.CustomsClearance.description,
      },
      {
        name: "viewport",
        content: "width=device-width, initial-scale=1, user-scalable=no",
      },
    ],
  },
  methods: {
    showHideContactsCustom() {
      if (this.globals.whatsappPopupVisible) {
        this.logGAEvent("Let's Chat", "Whatsapp open", `Warehouse Page`);
      }
      this.globals.whatsappPopupVisible = !this.globals.whatsappPopupVisible;
    },
  },
  data: () => ({
    customsclearanceItems: [
      {
        title: "Taking care of all your paperwork",
        description:
          "Navigating the intricacies of customs clearance and border services is vital for seamless logistics operations. Our expert team ensures hassle-free passage of goods across international borders, handling all necessary documentation and compliance procedures with precision and efficiency. From import/export regulations to duty calculations, we meticulously manage every aspect, saving your time and resources. With our tailored solutions, minimizing delays and maximizing cost-effectiveness. Trust us to streamline your customs clearance and border services, so you can focus on growing your business globally.",
        imageUrl: require("../../assets/new-site/services/Customs-clearance1.jpg"),
      },
      {
        title: "Simplifying complex cases",
        description:
          "When it comes to international shipments, we always must deal with regulations, border control, and customs clearance. At times, this can be a confusing and lengthy process, which is why are offering to handle all the customs paperwork for you. No need to waste time and deal with red tape anymore! Our team knows exactly what needs to be done to adhere to all regulations and ensure a smooth and fast customs process.",

        imageUrl: require("../../assets/new-site/services/Customs-clearance2.jpg"),
      },
    ],
    customsclearanceItemsQA: [
      {
        title: "Taking care of all your paperwork",
        description:
          "Navigating the intricacies of customs clearance and border services is vital for seamless logistics operations. Our expert team ensures hassle-free passage of goods across international borders, handling all necessary documentation and compliance procedures with precision and efficiency. From import/export regulations to duty calculations, we meticulously manage every aspect, saving your time and resources. With our tailored solutions, minimizing delays and maximizing cost-effectiveness. Trust us to streamline your customs clearance and border services, so you can focus on growing your business globally.",
        imageUrl: require("../../assets/new-site/services/Customs-clearance1.jpg"),
      },
      {
        title: "Simplifying complex cases",
        description:
          "When it comes to international shipments, we always must deal with regulations, border control, and customs clearance. At times, this can be a confusing and lengthy process, which is why are offering to handle all the customs paperwork for you. No need to waste time and deal with red tape anymore! Our team knows exactly what needs to be done to adhere to all regulations and ensure a smooth and fast customs process.",

        imageUrl: require("../../assets/new-site/services/Customs-clearance2.jpg"),
      },
    ],
  }),
  computed: {
    types() {
      return types;
    },
  },
});
